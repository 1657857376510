import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import ChevronUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ChevronDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { useState } from "react";

const StepForm = ({ steps, setSteps }) => {
  const [newStep, setNewStep] = useState("");

  const resetForm = () => {
    setNewStep("");
  };

  const addStep = () => {
    setSteps([...steps, newStep]);
    resetForm();
  };

  const handleKeyPress = (event, keys) => {
    if (keys.includes(event.key) && !event.shiftKey) {
      event.preventDefault();
      addStep();
    }
  };

  const moveStep = (index, dist) => {
    const newSteps = [...steps];
    const movedItem = newSteps[index];
    newSteps[index] = newSteps[index + dist];
    newSteps[index + dist] = movedItem;
    setSteps(newSteps);
  };

  const removeStep = (index) => {
    setSteps([...steps.slice(0, index), ...steps.slice(index + 1)]);
  };

  return (
    <>
      <Typography variant="h5" component="h3">
        Steps
      </Typography>
      <List component="ol">
        {steps.map((step, index) => (
          <ListItem key={index}>
            <ListItemIcon>{index + 1}</ListItemIcon>
            <ListItemText>{step}</ListItemText>
            <ListItemSecondaryAction>
              <IconButton onClick={() => moveStep(index, -1)}>
                <ChevronUpIcon />
              </IconButton>
              <IconButton onClick={() => moveStep(index, 1)}>
                <ChevronDownIcon />
              </IconButton>
              <IconButton onClick={() => removeStep(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem>
          <ListItemIcon />
          <ListItemText>
            <TextField
              label="Step"
              fullWidth
              multiline
              value={newStep}
              onChange={(e) => setNewStep(e.currentTarget.value)}
              onKeyDown={(e) => handleKeyPress(e, ["Tab", "Enter"])}
            />
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

StepForm.propTypes = {
  steps: PropTypes.array.isRequired,
  setSteps: PropTypes.func.isRequired,
};

StepForm.defaultProps = {
  steps: [],
  setSteps: () => {},
};

export default StepForm;
