import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteRecipe, getRecipeList } from "../services/recipes.service";
import ConfirmDelete from "./ConfirmDelete";

const RecipeList = () => {
  const [recipes, setRecipes] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    getRecipeList().then((res) => setRecipes(res));
  }, []);

  const handleEdit = (id) => {
    history.push(`/recipes/edit/${id}?ref=list`);
  };

  const handleDelete = (id) => {
    setOpen(true);
    setDeleteId(id);
  };

  const handleClose = async (shouldDelete) => {
    setOpen(false);
    setDeleteId(null);
    if (shouldDelete) {
      await deleteRecipe(deleteId);
      getRecipeList().then((res) => setRecipes(res));
    }
  };

  return (
    <>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h4" component="h2">
            Recipes
          </Typography>
        </Grid>
        <Grid item>
          <IconButton to="/recipes/add" component={Link}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>
      <List>
        {recipes.map((recipe) => (
          <ListItem
            button
            to={`/recipes/${recipe._id}`}
            component={Link}
            key={recipe._id}
          >
            <ListItemText primary={recipe.name} />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="edit recipe"
                onClick={() => handleEdit(recipe._id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete recipe"
                onClick={() => handleDelete(recipe._id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <ConfirmDelete open={open} onClose={handleClose} />
    </>
  );
};

export default RecipeList;
