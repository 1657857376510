import { Box, Button, Grid, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { saveRecipe, getRecipe } from "../services/recipes.service";
import IngredientForm from "./IngredientForm";
import StepForm from "./StepForm";
import { useHistory } from "react-router";

const RecipeForm = (props) => {
  const id = props.match.params.id;
  const [name, setName] = useState("");

  const [steps, setSteps] = useState([]);
  const [ingredients, setIngredients] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getRecipe(id).then((recipe) => {
      setName(recipe.name);
      setIngredients(recipe.ingredients);
      setSteps(recipe.steps);
    });
  }, [id]);

  const save = async () => {
    const newRecipe = await saveRecipe(
      {
        name,
        ingredients,
        steps,
      },
      id
    );
    return newRecipe;
  };

  const redirect = (recipe) => {
    const params = new URLSearchParams(history.location.search);
    if (params.get("ref") === "list") {
      history.push("/");
    } else {
      history.push(`/recipes/${recipe._id}`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const recipe = await save();
    redirect(recipe);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box py={2}>
          <TextField
            id="recipe-name"
            label="Recipe Name"
            variant="filled"
            fullWidth
            required
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </Box>
        <Box py={2}>
          <IngredientForm
            ingredients={ingredients}
            setIngredients={setIngredients}
          />
        </Box>
        <Box py={2}>
          <StepForm steps={steps} setSteps={setSteps} />
        </Box>

        <Box py={2}>
          <Grid container justify="flex-end">
            <Grid item>
              <Button type="submit" color="primary" variant="contained">
                Save Recipe
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  );
};

RecipeForm.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

export default RecipeForm;
