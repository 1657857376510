import DeleteIcon from "@material-ui/icons/Delete";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PropTypes from "prop-types";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  fill: {
    flex: 1,
  },
  ingredientList: {
    listStyle: "none",
    padding: 0,
  },
  textFieldSmall: {
    width: "6ch",
  },
}));

const defaultIngredient = {
  qty: 0,
  unit: "",
  name: "",
};

const IngredientForm = ({ ingredients, setIngredients }) => {
  const styles = useStyles();

  const [qtyInput, setQtyInput] = useState(null);
  const [newIngredient, setNewIngredient] = useState({ ...defaultIngredient });

  const setQty = (event) => {
    const qty = parseInt(event.currentTarget.value) || 0;
    setNewIngredient({ ...newIngredient, qty });
    event.currentTarget.value = qty.toString();
  };

  const resetForm = () => {
    setNewIngredient({ ...defaultIngredient });
    if (qtyInput) {
      qtyInput.select();
    }
  };

  const addIngredient = () => {
    if (
      newIngredient.qty === defaultIngredient.qty ||
      newIngredient.unit === defaultIngredient.unit ||
      newIngredient.name === defaultIngredient.name
    ) {
      return;
    }

    setIngredients([...ingredients, newIngredient]);
    resetForm();
  };

  const handleKeyPress = (event, keys) => {
    if (keys.includes(event.key) && !event.shiftKey) {
      event.preventDefault();
      addIngredient();
    }
  };

  const removeIngredient = (index) => {
    setIngredients([
      ...ingredients.slice(0, index),
      ...ingredients.slice(index + 1),
    ]);
  };

  return (
    <>
      <Typography variant="h5" component="h3">
        Ingredients
      </Typography>
      <List component="ul">
        {ingredients.map((ingredient, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
            <ListItemText>
              {ingredient.qty} {ingredient.unit} - {ingredient.name}
            </ListItemText>
            <ListItemSecondaryAction>
              <IconButton onClick={() => removeIngredient(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        <ListItem>
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText>
            <Grid container spacing={2}>
              <Grid item>
                <TextField
                  type="number"
                  inputRef={setQtyInput}
                  label="Qty"
                  className={styles.textFieldSmall}
                  value={newIngredient.qty}
                  onChange={setQty}
                  onKeyDown={(e) => handleKeyPress(e, ["Enter"])}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Unit"
                  className={styles.textFieldSmall}
                  value={newIngredient.unit}
                  onChange={(e) =>
                    setNewIngredient({
                      ...newIngredient,
                      unit: e.currentTarget.value,
                    })
                  }
                  onKeyDown={(e) => handleKeyPress(e, ["Enter"])}
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <TextField
                  label="Name"
                  fullWidth
                  value={newIngredient.name}
                  onChange={(e) =>
                    setNewIngredient({
                      ...newIngredient,
                      name: e.currentTarget.value,
                    })
                  }
                  onKeyDown={(e) => handleKeyPress(e, ["Tab", "Enter"])}
                />
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

IngredientForm.propTypes = {
  ingredients: PropTypes.array.isRequired,
  setIngredients: PropTypes.func.isRequired,
};

IngredientForm.defaultProps = {
  ingredients: [],
  setIngredients: () => {},
};

export default IngredientForm;
