import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Link, useHistory } from "react-router-dom";
import { getRecipe } from "../services/recipes.service";

const Recipe = (params) => {
  const id = params.match.params.id;
  const [recipe, setRecipe] = useState(null);
  const history = useHistory();

  useEffect(() => {
    getRecipe(id).then(setRecipe);
  }, [id]);

  const editRecipe = () => {
    history.push(`/recipes/edit/${id}?ref=details`);
  };

  return (
    <>
      <Link to="/">Back to all recipes</Link>
      {recipe && (
        <>
          <Box mb={2} mt={4}>
            <Grid container>
              <Grid item>
                <Typography variant="h4" component="h2">
                  {recipe.name}
                </Typography>
              </Grid>
              <div style={{ flex: 1 }} />
              <Grid item>
                <IconButton aria-label="edit recipe" onClick={editRecipe}>
                  <EditIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton aria-label="delete recipe">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          {Array.isArray(recipe.ingredients) && (
            <>
              <Typography variant="h5" component="h3">
                Ingredients
              </Typography>
              <ul>
                {recipe.ingredients.map((ingredient, index) => (
                  <li key={index}>
                    {ingredient.qty} {ingredient.unit} - {ingredient.name}
                  </li>
                ))}
              </ul>
            </>
          )}
          {Array.isArray(recipe.steps) && (
            <>
              <Typography variant="h5" component="h3">
                Steps
              </Typography>
              <ol>
                {recipe.steps.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ol>
            </>
          )}
        </>
      )}
    </>
  );
};

Recipe.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Recipe;
