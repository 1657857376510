import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";

const ConfirmDelete = ({ onClose, open }) => {
  const handleClose = () => {
    onClose(false);
  };

  const handleChoose = (confirmed) => {
    onClose(confirmed);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="dialog-title" color="primary">
        Are you sure?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => handleChoose(true)}
        >
          Yes
        </Button>
        <Button variant="contained" onClick={() => handleChoose(false)}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmDelete;
