import { Box, Container } from "@material-ui/core";
import { Route, Switch } from "react-router";

const { default: Header } = require("./components/Header");
const { default: RecipeForm } = require("./components/RecipeForm");
const { default: RecipeList } = require("./components/RecipeList");
const { default: Recipe } = require("./components/Recipe");

const App = () => {
  return (
    <>
      <Header />
      <Container maxWidth="sm">
        <Box pt={4}>
          <Switch>
            <Route exact path="/recipes/add" component={RecipeForm} />
            <Route exact path="/recipes/edit/:id" component={RecipeForm} />
            <Route exact path={["/", "/recipes"]} component={RecipeList} />
            <Route path="/recipes/:id" component={Recipe} />
          </Switch>
        </Box>
      </Container>
    </>
  );
};

export default App;
