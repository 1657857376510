import http from "../http-common";

export const getRecipeList = async () => {
  return http.get(`/search`).then((res) => res.data);
};

export const getRecipe = async (id) => {
  return http.get(`/search?id=${id}`).then((res) => res.data[0]);
};

export const saveRecipe = async (recipe, id) => {
  if (id) {
    return http.put(`/update?id=${id}`, recipe).then((res) => res.data);
  } else {
    return http.post(`/create`, recipe).then((res) => res.data);
  }
};

export const deleteRecipe = async (id) => {
  return http.delete(`/delete?id=${id}`).then((res) => res.data);
};
